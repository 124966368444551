<template>
  <div class="manage">

    <!--    搜索区域开始   -->
    <el-collapse>
      <el-collapse-item title="搜索" name="1">
        <el-form :model="searchForm"
                 :inline="true"
                 label-width="80px">
          <el-form-item label="键名" prop="key">
            <el-input placeholder="请输入键名" v-model="searchForm.key"/>
          </el-form-item>
          <el-form-item label="备注" prop="note">
            <el-input placeholder="请输入备注" v-model="searchForm.note"/>
          </el-form-item>
        </el-form>
      </el-collapse-item>
    </el-collapse>
    <!--    搜索区域结束   -->

    <!--    分割线-->
    <el-divider></el-divider>

    <!-- 弹窗区域开始 -->
    <el-dialog
      :title="this.scene === 1 ? '新增' : '编辑'"
      :visible.sync="showDialog"
      width="50%"
      :before-close="cancelDialog">
      <!-- 用户的表单信息 -->
      <el-form
        ref="form"
        :inline="false"
        :model="form"
        label-width="80px"
        :rules="rules"
      >
        <el-form-item label="键名" prop="key">
          <el-input placeholder="请输入键名" v-model="form.key"/>
        </el-form-item>
        <el-form-item label="备注" prop="note">
          <el-input placeholder="请输入备注" v-model="form.note"/>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select placeholder="请选择状态" v-model="form.type" style="width: 100%" @change="changeType">
            <el-option v-for="(item, index) in pageData.select.site_type"
                       :key="index"
                       :label="item"
                       :value="index*1"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="键值" prop="value">
          <template>
            <!--单行输入框-->
            <div v-if="form.type === 1">
              <el-input placeholder="请输入备注" v-model="form.value"/>
            </div>

            <!--单行输入框-->
            <div v-if="form.type === 2">
              <el-input type="textarea" placeholder="请输入备注" v-model="form.value"/>
            </div>

            <!--键值对-->
            <div v-if="form.type === 3">
              <el-table :data="form.dict" border>
                <el-table-column label="key">
                  <template v-slot="{row}">
                    <el-input v-model="row.key"/>
                  </template>
                </el-table-column>
                <el-table-column label="value">
                  <template v-slot="{row}">
                    <el-input v-model="row.value"/>
                  </template>
                </el-table-column>

                <el-table-column label="操作" width="80">
                  <template v-slot="{$index}">
                    <el-button v-if="$index === 0" @click="pushItem" size="mini" class="el-icon-plus"/>
                    <el-button v-else @click="spliceItem($index)" type="danger" size="mini" class="el-icon-minus"/>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <!--日期-->
            <div v-if="form.type === 4">
              <el-date-picker
                style="width: 100%;"
                v-model="form.value"
                type="datetime"
                placeholder="选择日期时间">
              </el-date-picker>
            </div>

            <!--时间段-->
            <div v-if="form.type === 5">
              <el-date-picker
                style="width: 100%"
                v-model="form.value"
                type="datetimerange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '00:00:00']">
              </el-date-picker>
            </div>

            <!--图片上传-->
            <div v-if="form.type === 6">
              <McUpload :limit="10"
                        type="image"
                        @event="changDataValue"
                        :files="form.files"
                        field="files"/>
            </div>

            <!--音频上传-->
            <div v-if="form.type === 7">
              <McUpload :limit="10"
                        type="video"
                        @event="changDataValue"
                        :files="form.files"
                        field="value"/>
            </div>

            <!--office上传-->
            <div v-if="form.type === 8">
              <McUpload :limit="10"
                        type="office"
                        @event="changDataValue"
                        field="value"
                        :files="form.files"/>
            </div>
          </template>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="cancelDialog">取 消</el-button>
          <el-button type="primary" @click="submitClick">确 定</el-button>
        </span>
    </el-dialog>

    <el-dialog
      title="更多"
      :visible.sync="moreDialog"
      width="80%"
      :before-close="cancelDialog">
      <SiteItem ref="childRef"></SiteItem>
    </el-dialog>
    <!-- 弹窗区域结束 -->

    <!-- 表头按钮区域开始 -->
    <div class="manage-header">
      <el-button size="mini" class="el-icon-plus" type="primary" @click="addClick" plain>新增</el-button>
      <span>
        <el-button size="mini" class="el-icon-search" @click="searchClick">搜索</el-button>
        <el-button size="mini" class="el-icon-refresh-right" @click="resetClick">重置</el-button>
      </span>
    </div>
    <!-- 表头按钮区域结束 -->

    <!-- 表格 -->
    <el-table
      :data="pageData.list"
      border
      v-loading="tableLoad">
      <el-table-column prop="id" label="ID"/>
      <el-table-column prop="key" label="键名"/>
      <el-table-column prop="type_text" label="类型"/>
      <el-table-column prop="note" label="备注"/>
      <el-table-column prop="create_time" label="创建时间"/>
      <el-table-column prop="update_time" label="更新时间"/>
      <el-table-column label="操作">
        <template v-slot="{row}">
          <div>
            <el-button size="mini" @click="editClick(row)" class="el-icon-edit"> 编辑</el-button>
            <el-button size="mini" @click="deleteClick(row)" class="el-icon-delete" type="danger"> 删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import '@/assets/static/css/base.css'
import service from '@/service/System/SiteService'
export default service
</script>
